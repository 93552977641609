import BreadCrumb from "Common/BreadCrumb";
import { useState } from "react";
import { Container, Button, Card, Form, Modal } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";

const Setting = () => {
    const userId = localStorage.getItem("userId");
  const userDetails = localStorage.getItem("adminDetails");
  const userPassword = userDetails && JSON.parse(userDetails)?.password;

  const [showTv, setTv] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [formData, setFormData] = useState("");
  const [formErrors, setFormErrors] = useState("");
  const [touchedFields, setTouchedFields] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleTv = () => {
    setTv(!showTv);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData(value);

    setTouchedFields(true);

    validateForm( value );
  };

  const validateForm = (data: string) => {
    let isValid = true;
    let errors: string = '';

    if (!data) {
      errors = "Password is required";
      isValid = false;
    } else if (data != userPassword) {
      errors = "Password didn't match";
      isValid = false;
    } else {
      errors = "";
    }

    setFormErrors(errors);
    return isValid;
  };

  const resetTV = async () => {
    setIsLoading(true);
    const { response } = await authServices.resetTV({
      password: formData,
      user_id: userId || '',
    });
    setIsLoading(false);
    if (response) {
      if (response.status) {
        snackbarUtil.success(response.msg);
        toggleTv();
        setFormData("");
      } else {
        snackbarUtil.error(response?.msg || "An error occurred. Please try again.");
      }
    }
  };

  const handleSubmit = () => {
    if (validateForm(formData)) {
        resetTV();
    } else {
      setTouchedFields(true);
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="position-relative mb-3 d-flex justify-content-between align-items-center">
          <BreadCrumb title="Setting" pageTitle="Dashboard" back />
          <Button variant="subtle-dark" onClick={toggleTv}>
            <i className="fs-lg align-middle ri-tv-line me-1"></i> Reset Tv
          </Button>
        </div>

        <Card>
          <Card.Body>
            <h5>Reset TV and ScoreBoard URL</h5>
          </Card.Body>
        </Card>

        <Modal show={showTv} onHide={toggleTv} className="zoomIn" scrollable>
          <Modal.Header className="modal-title fw-bold">
            <div>Reset TV and ScoreBoard URL</div>
            <Button variant="light btn-sm" onClick={toggleTv}>
              <i className="ri-close-line fs-xl align-middle"></i>
            </Button>
          </Modal.Header>
          <Modal.Body className="pt-2">
          <div className="form-floating mt-3 position-relative">
            
            <Form.Control
              name="password"
              type={passwordShow ? "text" : "password"}
              placeholder="Password"
              value={formData}
              onChange={handleInputChange}
              isInvalid={!!formErrors && touchedFields}
            />
            <Form.Label>Password</Form.Label>
            <Button
              variant="link"
              className="position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
              type="button"
              id="password-addon"
              onClick={() => setPasswordShow(!passwordShow)}
            >
              <i
                className={`${
                  passwordShow ? "ri-eye-off-fill" : "ri-eye-fill"
                } align-middle`}
              ></i>
            </Button>
            {formErrors && touchedFields && (
              <Form.Control.Feedback type="invalid">
                {formErrors}
              </Form.Control.Feedback>
            )}
            </div>
          </Modal.Body>
          <Modal.Footer className="border-top">
            <Button onClick={handleSubmit}>Update</Button>
            <Button variant="subtle-dark" onClick={toggleTv}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};

export default Setting;
