import { Dispatch, SetStateAction, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { settingServices } from 'Utils/setting/services';
import snackbarUtil from 'Utils/snackBarUtil';

interface DeleteDomainModalProps {
    show: any;
    clickHandler: any;
    password: string;
    userId: any;
    getWebsiteList: () => Promise<void>;
    setDomainNameData: any;
    setDelete: any;
    setPasswordData: any;
    nameDomain: string;
    domainNameData: any;
    userIds: string | undefined;
    showPassword: boolean;
    setPassword: Dispatch<SetStateAction<boolean>>;
}

const DeleteDomain = ({
  setPassword,
  showPassword,
  domainNameData,
  userIds,
  setDelete,
  nameDomain,
  setPasswordData,
  show,
  clickHandler,
  password,
  userId,
  getWebsiteList,
  setDomainNameData,
}: DeleteDomainModalProps) => {
  const [errors, setErrors] = useState({ password: '', domainName: '' });
  const [formSubmitted, setFormSubmitted] = useState(false);

  const getDeletedDomain = async (id: string) => {
    const { response } = await settingServices.getDeletedDomain(id, {
      password: password,
      userId: userId,
    });
    if (response?.status) {
      snackbarUtil.success(response?.msg);
      getWebsiteList();
      setDomainNameData("");
      setDelete(false);
      setPasswordData("");
    } else {
      snackbarUtil.error(response?.msg);
    }
  };

  const handleDomain = (e: any) => {
    const value = e.target.value;
    setDomainNameData(value);

    setErrors((prevErrors) => ({
      ...prevErrors,
      domainName: value !== nameDomain ? 'Invalid domain name' : '',
    }));
  };

  const handlePasswordChange = (e: any) => {
    const value = e.target.value;
    setPasswordData(value);

    setErrors((prevErrors) => ({
      ...prevErrors,
      password: value.trim() === '' ? 'Password is required' : '',
    }));
  };

  const validate = () => {
    const newErrors: any = {
      password: password.trim() ? '' : 'Password is required',
      domainName: domainNameData === nameDomain ? '' : 'Invalid domain name',
    };

    setErrors(newErrors);

    return Object.keys(newErrors).every((key) => newErrors[key] === '');
  };

  const handleDelete = () => {
    setFormSubmitted(true);
    if (validate() && userIds) {
      getDeletedDomain(userIds);
    }
  };

  return (
    <Modal show={show} onHide={clickHandler} className="zoomIn" scrollable>
      <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
        <div className="d-flex align-items-center">Delete Domain of {nameDomain}</div>
        <Button variant="light btn-sm" onClick={clickHandler}>
          <i className="ri-close-line fs-xl align-middle"></i>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form autoComplete='off'>
          <Form.Group>
            <Form.Label>Domain Name</Form.Label>
            <div className="form-icon right mb-3">
              <Form.Control
                name="unique_domain_field"
                type="text"
                placeholder="Enter Domain Name"
                value={domainNameData}
                onChange={handleDomain}
                isInvalid={!!errors.domainName || (formSubmitted && !!errors.domainName)}
                autoComplete="off"
                id="unique_domain_input"
              />
              <Form.Control.Feedback type="invalid">{errors.domainName}</Form.Control.Feedback>
            </div>
            <Form.Label>Password</Form.Label>
            <div className="form-icon right">
              <Form.Control
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder="Enter Password"
                value={password}
                onChange={handlePasswordChange}
                isInvalid={!!errors.password || (formSubmitted && !!errors.password)}
                autoComplete="new-password"
                id="new_password"
              />
              <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
              <Button
                variant="link"
                className="btn-sm position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
                type="button"
                id="password-addon"
                onClick={() => setPassword(!showPassword)}
              >
                <i className={`${showPassword ? "ri-eye-fill" : "ri-eye-off-fill"} align-middle`}></i>
              </Button>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="border-top">
        <Button variant="danger" onClick={handleDelete}>Delete</Button>
        <Button variant="light" onClick={clickHandler}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteDomain;
