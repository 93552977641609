import BreadCrumb from "Common/BreadCrumb";
import { Container, Tab, Nav, Button } from "react-bootstrap";
import Statement from "./Statement";
import { useEffect, useState } from "react";
import OpenBets from "./OpenBets";
import SettledBets from "./SettledBets";
import BalanceTable from "Common/Tables/BalanceTable";
import BetList from "./BetList";
import NetExposure from "./NetExposure";
import { authServices } from "Utils/auth/services";
import { useParams } from "react-router-dom";
import { flatMap } from "lodash";


export interface balancePayload{
balance: number,
credit_reference:number
is_online:number
liability: number
name: string
parent_id: null | string
partnership: number
profit_loss: number
share: number
user_name:string
_id:string
}

const DownLineReport = () => {
  const [showFilter, setFilter] = useState(false);
  const [showHeading, setHeading] = useState("");
  const [showFilteBtn, setFilterBtn] = useState(true);
  const [activeTab, setActiveTab] = useState<string>("Balance");

  const {  userid } = useParams();
  const [balanceData, setBalanceData] = useState<balancePayload>()


  const fetchBalance = async () => {
    if (!userid) return;
    try {
        const { response } = await authServices.showBalance({ user_id:userid, full_exposure:true });
        setBalanceData(response.data);
        // setExp(response.data.liability);
    } catch (error) {
        console.error('Error fetching balance data:', error);
    }
};

  const handleNavClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const innerText = event.currentTarget.innerText;
    setHeading(innerText);
    setActiveTab(
      event.currentTarget.getAttribute("data-rr-ui-event-key") || "Balance"
    );


    if (innerText === "News Update") {
      setFilterBtn(false);
    } else {
      setFilterBtn(true);
    }
    if(innerText === "Balance"){
      fetchBalance();
    }
  };



useEffect(()=>{
  fetchBalance();
}, [])



  return (
    <div className="page-content">
      <Container fluid>
        <div
          className="position-relative mb-2 d-flex justify-content-between align-items-center"
          style={{ minHeight: "43px" }}
        >
          <BreadCrumb
            title={showHeading ? showHeading : "Reports"}
            pageTitle="Dashboard"
            back
          />
          {showFilteBtn ? (
            <Button
              variant="subtle-dark"
              onClick={() => setFilter(!showFilter)}
            >
              <i className="fs-lg align-middle ri-filter-3-line"></i> Filter
            </Button>
          ) : null}
        </div>

        <Tab.Container defaultActiveKey={"Balance"} activeKey={activeTab}>
          <Nav
            as="ul"
            variant="pills"
            className="arrow-navtabs nav-secondary mb-3 flex-nowrap overflow-auto text-nowrap"
            style={{ height: "48px" }}
          >
            <Nav.Item as="li" className="mx-2">
              <Nav.Link eventKey="Balance" onClick={handleNavClick}>
                Balance{" "}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="mx-2">
              <Nav.Link eventKey="BetLIst" onClick={handleNavClick}>
                Bet List{" "}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="me-2">
              <Nav.Link eventKey="Statement" onClick={handleNavClick}>
                Account Statement{" "}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="mx-2">
              <Nav.Link eventKey="NetExposure" onClick={handleNavClick}>
                Net Exposure{" "}
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content className="text-muted">
            <Tab.Pane eventKey="Balance">
              <BalanceTable balanceData={balanceData}/>
            </Tab.Pane>
            <Tab.Pane eventKey="Statement">
              <Statement
                filter={showFilter ? true : false}
                activeTab={activeTab}
                isReport={true}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="BetLIst">
              <BetList
                filter={showFilter ? true : false}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="NetExposure">
              <NetExposure
                filter={showFilter ? true : false}
                activeTab={activeTab}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Container>
    </div>
  );
};

export default DownLineReport;
