import React, { Dispatch, SetStateAction } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';

interface BreadCrumbProps {
    title: string;
    pageTitle: string;
    back?: boolean;
    userDataBreadCrumb?: any;
    setUserId?: Dispatch<SetStateAction<string>>;
}

const BreadCrumb = ({ title, pageTitle, back, userDataBreadCrumb, setUserId }: BreadCrumbProps) => {
    const navigate = useNavigate();

    const handleBack = () => {
        if (userDataBreadCrumb && userDataBreadCrumb?.length > 1) {
            const secondLastItem = userDataBreadCrumb[userDataBreadCrumb.length - 1];

            if (secondLastItem && setUserId) {
                setUserId(secondLastItem?.user_id);  
            }
        } else if (userDataBreadCrumb?.length === 1) {
            const secondLastItem = userDataBreadCrumb[0];

            if (secondLastItem && setUserId) {
                setUserId(secondLastItem?.user_id);  
            }
        } else {
            navigate(-1); 
        }
    };

    const handleNavigate = (index: number) => {
        if (userDataBreadCrumb && userDataBreadCrumb.length > index + 1) {
            const nextItem = userDataBreadCrumb[index + 1]; 
            if (nextItem && setUserId) {
                
                setUserId(nextItem?.user_id);  
            }
        }
    };

    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <div className="page-title-box d-sm-flex align-items-center">
                        <div className='d-flex align-items-center'>
                            {back ? (
                                <Button variant='sm' className='py-0' onClick={handleBack}>
                                    <i className='fs-xl align-middle ri-arrow-left-line'></i>
                                </Button>
                            ) : null}
                            <h4 className="mb-0">{title}</h4>
                        </div>

                        <div className="page-title-right d-none d-sm-block ms-3">
                            {userDataBreadCrumb && (
                                <ol className="breadcrumb m-0">
                                    {userDataBreadCrumb.map((items: any, index: number) => {
                                        const isLastItem = index === userDataBreadCrumb.length - 1;
                                        return (
                                            <li
                                                key={index}
                                                className={`breadcrumb-item ${isLastItem ? "active" : ""}`}
                                                aria-current={isLastItem ? "page" : undefined}
                                            >
                                                {isLastItem ? (
                                                    items.user_name  
                                                ) : (
                                                    <Link to="#" onClick={() => handleNavigate(index)}>
                                                        {items.user_name} 
                                                    </Link>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ol>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default BreadCrumb;
