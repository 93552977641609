import DetailsRight from 'pages/MatchDetail/DetailsRight';
import { Button, Modal } from 'react-bootstrap';

interface BetsModalProps {
	show: any;
	clickHandler: any;
    keys:string;
    mrkrtId:string
    marketName: string
}

const BetsModal = ({show, clickHandler, keys, mrkrtId, marketName} : BetsModalProps) => {
    const userDetails = localStorage.getItem("adminDetails");
    const useridType = userDetails && JSON.parse(userDetails)?.user_type_id;
    return (
        <Modal size="lg" show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <div>Bet List <span className='text-muted fw-normal'>({marketName})</span></div>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='pt-1 fs-md px-0 pb-0'>
                <DetailsRight useridType={useridType} keys={keys} mrkrtId={mrkrtId} rightv={false}/>
            </Modal.Body>
        </Modal>
    );
};

export default BetsModal;