import BreadCrumb from "Common/BreadCrumb";
import { ListPagination } from "Common/ListPagination";
import TableEntry from "Common/TableEntry";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  Container,
  Card,
  Form,
  Button,
  Table,
  Pagination,
  Modal,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";

interface ParentLevl {
  user_id: string;
  user_name: string;
  name: string;
  user_type_id: number;
  domain_name: string;
  parent_id: string;
  parent_user_name: string;
  accessToken: string;
  login_status: string;
  is_online: number;
  message: string;
  ip_address: string;
  browser_info: string;
  parent_level_ids: ParentLevelId[];
  expireAt: string;
  login_time: string;
  logout_time: string;
}

interface ParentLevelId {
  user_id: string;
  user_name: string;
  user_type_id: number;
}

const OnlineUser = () => {
  const [showModal, setModal] = useState(false);
  const [aciveLog, setActiveLog] = useState<ParentLevl[]>([]);
  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(1);

  const [listTotal, setListTotal] = useState<ProfitLossTotal>({
    total: 0,
    page: 0,
  });

  const nav = useNavigate();

  const toggleModal = () => {
    setModal(!showModal);
  };

  const getActiveLogs = async (filters: any = {}) => {
    const { response } = await authServices.getActiveLog({
      limit: limit,
      page,
      ...filters,
    });

    setActiveLog(response?.data);
    setListTotal({
      total: response?.metadata
        ? response?.metadata?.total / (filters?.limit || limit)
        : 0,
      page,
    });
  };

  useEffect(() => {
    getActiveLogs(); 

    const intervalId = setInterval(() => {
        getActiveLogs();
    }, 5000);

    return () => {
        clearInterval(intervalId); 
    };
}, []);

  const allLogout = async () => {
    const { response } = await authServices.getAllUserLogout({});

    if (response?.status) {
      snackbarUtil.success(response.msg);
      localStorage.removeItem("token");
      localStorage.removeItem("adminDetails");
      localStorage.removeItem("adminRefreshToken");
      localStorage.removeItem("operatorId");
      localStorage.removeItem("userId");
      nav("/login");
    } else {
      snackbarUtil.error(response?.msg);
    }
  };

  const handleLogout = () => {
    allLogout();
  };

  const handlePageFilter = (page: number, limit: number) => {
    setPage(page);
    setLimit(limit);

    getActiveLogs({
      page: page,
      limit: limit,
    });
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="position-relative mb-2">
          <BreadCrumb title="Online User" pageTitle="Dashboard" back />
          <Button
            variant="dark"
            className="position-absolute top-0 end-0"
            onClick={toggleModal}
          >
            All User Logout
          </Button>
        </div>
        <Card className="pb-3">
          <div className="table-responsive">
            <Table className="table-striped table-nowrap">
              <thead>
                <tr>
                  <th>
                    <div className="form-check form-check-dark">
                      <Form.Check type="checkbox" id="openUser" />
                    </div>
                  </th>
                  <th>S. No.</th>
                  <th>User Name</th>
                  <th>Login Time</th>
                  <th>Status</th>
                  <th>IP Address</th>
                  <th>Browser Info</th>
                </tr>
              </thead>
              <tbody>
                {aciveLog?.map((items, id: number) => {
                  return (
                    <tr key={id}>
                      <td>
                        <div className="form-check form-check-dark">
                          <Form.Check type="checkbox" id="openUser" />
                        </div>
                      </td>
                      <td>{(page - 1) * limit + id + 1}</td>
                      <td>{items?.user_name}</td>
                      <td>
                        {moment(items?.login_time).format(
                          "DD-MM-YYYY, hh:mm:ss A"
                        )}
                      </td>
                      <td className={`${items.login_status?.toLowerCase() == 'login_success' ? 'text-success' : 'text-danger'}`}>{items?.message}</td>
                      <td>{items?.ip_address}</td>
                      <td>{items?.browser_info}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <nav aria-label="Page navigation" className="px-3 d-flex flex-column flex-sm-row align-items-center">
            <TableEntry
              limit={limit}
              handlelimitFilter={(value: number) =>
                handlePageFilter(page, value)
              }
            />
            <ListPagination
              listTotal={listTotal}
              handlePageFilter={(value: number) =>
                handlePageFilter(value, limit)
              }
            />
          </nav>
        </Card>
      </Container>

      <Modal
        show={showModal}
        onHide={toggleModal}
        className="zoomIn"
        scrollable
      >
        <Modal.Body className="text-center ">
          <Button
            variant="light btn-sm"
            className="position-absolute"
            style={{ right: "1rem" }}
            onClick={toggleModal}
          >
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
          <i className="bi bi-exclamation-triangle text-warning display-5"></i>
          <h4 className="mt-3">Logout All User</h4>
          <p className="fs-lg mt-4">
            Are you sure you want to Logout all user account?
          </p>
          <div className="mt-4">
            <Button variant="light" className="mx-2" onClick={toggleModal}>
              No
            </Button>
            <Button className="mx-2" onClick={handleLogout}>
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OnlineUser;
